<form [formGroup]="form!" (submit)="save()">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title>Prisoner Info Part</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="general">
          <div>
            <!-- prisonerId -->
            <mat-form-field>
              <input
                matInput
                [formControl]="prisonerId"
                placeholder="prisoner ID"
              />
              <mat-error
                *ngIf="
                  prisonerId.errors?.required &&
                  (prisonerId.dirty || prisonerId.touched)
                "
                >ID required</mat-error
              >
              <mat-error
                *ngIf="
                  prisonerId.errors?.maxLength &&
                  (prisonerId.dirty || prisonerId.touched)
                "
                >ID too long</mat-error
              >
              <mat-error
                *ngIf="
                  prisonerId.errors?.pattern &&
                  (prisonerId.dirty || prisonerId.touched)
                "
                >invalid ID</mat-error
              >
            </mat-form-field>

            &nbsp;
            <!-- sex -->
            <mat-form-field style="width: 5em">
              <mat-select [formControl]="sex" placeholder="sex">
                <mat-option [value]="undefined">---</mat-option>
                <mat-option [value]="'M'">male</mat-option>
                <mat-option [value]="'F'">female</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- prisonId -->
            <cadmus-lookup-pin
              lookupKey="prison"
              label="prison"
              [initialValue]="orPrisonId"
              (entryChange)="onPrisonEntryChange($event)"
            ></cadmus-lookup-pin>
          </div>
          <h4>name</h4>
          <div>
            <!-- name -->
            <cadmus-refs-proper-name
              [name]="personName"
              [langEntries]="pnLangEntries || []"
              [typeEntries]="pnTypeEntries || []"
              (nameChange)="onPersonNameChange($event)"
            >
            </cadmus-refs-proper-name>
          </div>
          <div>
            <!-- origin -->
            <mat-form-field>
              <input matInput [formControl]="origin" placeholder="origin" />
              <mat-error
                *ngIf="
                  origin.errors?.maxLength && (origin.dirty || origin.touched)
                "
                >origin too long</mat-error
              >
            </mat-form-field>
          </div>
          <div>
            <!-- birth -->
            <cadmus-refs-historical-date
              label="birth"
              [date]="birth.value || undefined"
              (dateChange)="onBirthDateChange($event)"
            ></cadmus-refs-historical-date>
          </div>
          <div>
            <!-- death -->
            <cadmus-refs-historical-date
              label="death"
              [date]="death.value || undefined"
              (dateChange)="onDeathDateChange($event)"
            ></cadmus-refs-historical-date>
          </div>
        </mat-tab>
        <mat-tab label="trial">
          <div>
            <!-- charge (bound) -->
            <mat-form-field *ngIf="trChargeEntries?.length">
              <mat-select [formControl]="charge" placeholder="charge">
                <mat-option *ngFor="let e of trChargeEntries" [value]="e.id">{{
                  e.value
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- charge (free) -->
            <mat-form-field *ngIf="!trChargeEntries?.length">
              <input matInput [formControl]="charge" placeholder="charge" />
              <mat-error
                *ngIf="
                  charge.errors?.maxLength && (charge.dirty || charge.touched)
                "
                >charge too long</mat-error
              >
            </mat-form-field>
          </div>
          <div>
            <!-- judgement (bound) -->
            <mat-form-field *ngIf="trJudgementEntries?.length">
              <mat-select [formControl]="judgement" placeholder="judgement">
                <mat-option
                  *ngFor="let e of trJudgementEntries"
                  [value]="e.id"
                  >{{ e.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <!-- judgement (free) -->
            <mat-form-field *ngIf="!trJudgementEntries?.length">
              <input
                matInput
                [formControl]="judgement"
                placeholder="judgement"
              />
              <mat-error
                *ngIf="
                  judgement.errors?.maxLength &&
                  (judgement.dirty || judgement.touched)
                "
                >judgement too long</mat-error
              >
            </mat-form-field>
          </div>
          <h3>detention period</h3>
          <div>
            <!-- detStart -->
            <cadmus-refs-historical-date
              label="det.start"
              [date]="detStart.value || undefined"
              (dateChange)="onDetStartDateChange($event)"
            ></cadmus-refs-historical-date>
          </div>
          <div>
            <!-- detEnd -->
            <cadmus-refs-historical-date
              label="det.end"
              [date]="detEnd.value || undefined"
              (dateChange)="onDetEndDateChange($event)"
            ></cadmus-refs-historical-date>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
