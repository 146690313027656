<div style="margin: 0 16px">
  <div class="banner">
    <img
      class="banner-img"
      srcset="
        ./assets/img/banner-1024.jpg 1024w,
        ./assets/img/banner-512.jpg   512w
      "
      sizes="(max-width: 600px) 512px, 1024px"
    />
  </div>
  <article>
    <h2>Captive Voices - Inquisition Prison Graffiti</h2>
    <p>
      PhD research project of Anna Clara Basilicó (<a
        href="https://www.unive.it"
        target="_blank"
        >University of Ca' Foscari</a
      >
      and
      <a href="https://www.unipd.it" target="_blank">University of Padua</a>),
      department of Digital Humanities. The research is supervised by professors
      Stefano Dall'Aglio and Flavia De Rubeis.
    </p>
    <p>
      Welcome to the project editing dashboard. Access to the system is
      restricted.
    </p>
    <p *ngIf="!logged" style="margin-bottom: 8px">
      <a mat-mini-fab color="primary" routerLink="/login"
        ><mat-icon>login</mat-icon></a
      ><span style="margin-left: 8px">please login</span>
    </p>
  </article>
</div>
