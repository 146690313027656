<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>lock_open</mat-icon>
    <mat-card-title>
      <strong>Reset Password</strong>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      If you forgot your password, reset it from this page. Just enter your
      email address and click the <kbd>reset password</kbd>
      button; you will get an email message, containing a link to be followed
      for resetting the password.
    </p>
    <p>
      When you click on that link, a new temporary password will be sent to your
      email message: you must then login with it, and change the password as you
      prefer.
    </p>

    <form [formGroup]="form" (submit)="reset()">
      <div>
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="email"
            placeholder="email"
          />
          <mat-error
            *ngIf="email.hasError('required') && (email.dirty || email.touched)"
          >
            email address required
          </mat-error>
          <mat-error
            *ngIf="email.hasError('email') && (email.dirty || email.touched)"
          >
            invalid email address
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions>
    <button
      mat-raised-button
      color="warn"
      [disabled]="form.invalid || busy"
      (click)="reset()"
    >
      reset password
    </button>
  </mat-card-actions>
</mat-card>
